var w = Object.defineProperty;
var S = (o, e, t) => e in o ? w(o, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : o[e] = t;
var h = (o, e, t) => (S(o, typeof e != "symbol" ? e + "" : e, t), t);
function v(o) {
  return !(o !== o || o === 1 / 0 || o === -1 / 0);
}
function x(o, e, t) {
  if (!v(e))
    throw new TypeError("Expected `limit` to be a finite number");
  if (!v(t))
    throw new TypeError("Expected `interval` to be a finite number");
  const s = [];
  let r = [], i = 0;
  const n = function() {
    i++;
    const a = setTimeout(function() {
      i--, s.length > 0 && n(), r = r.filter(function(u) {
        return u !== a;
      });
    }, t);
    r.indexOf(a) < 0 && r.push(a);
    const c = s.shift();
    c.resolve(o.apply(c.self, c.args));
  }, l = function(...a) {
    const c = this;
    return new Promise(function(u, g) {
      s.push({
        resolve: u,
        reject: g,
        args: a,
        self: c
      }), i < e && n();
    });
  };
  return l.abort = function() {
    r.forEach(clearTimeout), r = [], s.forEach(function(a) {
      a.reject(function() {
        Error.call(this, "Throttled function aborted"), this.name = "AbortError";
      });
    }), s.length = 0;
  }, l;
}
class k {
  constructor() {
    h(this, "isCDNUrl", (e = "") => e.indexOf("/cdn/") > -1);
    h(this, "getOptionsPage", (e, t = 25, s = 1) => ({
      ...e,
      per_page: t,
      page: s
    }));
    h(this, "delay", (e) => new Promise((t) => setTimeout(t, e)));
    h(this, "arrayFrom", (e = 0, t) => [...Array(e)].map(t));
    h(this, "range", (e = 0, t = e) => {
      const s = Math.abs(t - e) || 0, r = e < t ? 1 : -1;
      return this.arrayFrom(s, (i, n) => n * r + e);
    });
    h(this, "asyncMap", async (e, t) => Promise.all(e.map(t)));
    h(this, "flatMap", (e = [], t) => e.map(t).reduce((s, r) => [...s, ...r], []));
    /**
     * @method escapeHTML
     * @param  {String} string text to be parsed
     * @return {String} Text parsed
     */
    h(this, "escapeHTML", function(e) {
      const t = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      }, s = /[&<>"']/g, r = RegExp(s.source);
      return e && r.test(e) ? e.replace(s, (i) => t[i]) : e;
    });
  }
  /**
   * @method stringify
   * @param  {Object} params
   * @param  {String} prefix
   * @param  {Boolean} isArray
   * @return {String} Stringified object
   */
  stringify(e, t, s) {
    const r = [];
    for (const i in e) {
      if (!Object.prototype.hasOwnProperty.call(e, i))
        continue;
      const n = e[i], l = s ? "" : encodeURIComponent(i);
      let a;
      typeof n == "object" ? a = this.stringify(
        n,
        t ? t + encodeURIComponent("[" + l + "]") : l,
        Array.isArray(n)
      ) : a = (t ? t + encodeURIComponent("[" + l + "]") : l) + "=" + encodeURIComponent(n), r.push(a);
    }
    return r.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {String} regionCode region code, could be eu, us, cn, ap or ca
   * @return {String} The base URL of the region
   */
  getRegionURL(e) {
    const t = "api.storyblok.com", s = "api-us.storyblok.com", r = "app.storyblokchina.cn", i = "api-ap.storyblok.com", n = "api-ca.storyblok.com";
    switch (e) {
      case "us":
        return s;
      case "cn":
        return r;
      case "ap":
        return i;
      case "ca":
        return n;
      default:
        return t;
    }
  }
}
const P = function(o, e) {
  const t = {};
  for (const s in o) {
    const r = o[s];
    e.indexOf(s) > -1 && r !== null && (t[s] = r);
  }
  return t;
}, O = (o) => o === "email", A = () => ({
  singleTag: "hr"
}), C = () => ({
  tag: "blockquote"
}), L = () => ({
  tag: "ul"
}), E = (o) => ({
  tag: [
    "pre",
    {
      tag: "code",
      attrs: o.attrs
    }
  ]
}), U = () => ({
  singleTag: "br"
}), H = (o) => ({
  tag: `h${o.attrs.level}`
}), N = (o) => ({
  singleTag: [
    {
      tag: "img",
      attrs: P(o.attrs, ["src", "alt", "title"])
    }
  ]
}), j = () => ({
  tag: "li"
}), I = () => ({
  tag: "ol"
}), M = () => ({
  tag: "p"
}), z = (o) => ({
  tag: [
    {
      tag: "span",
      attrs: {
        "data-type": "emoji",
        "data-name": o.attrs.name,
        emoji: o.attrs.emoji
      }
    }
  ]
}), q = () => ({
  tag: "b"
}), F = () => ({
  tag: "s"
}), V = () => ({
  tag: "u"
}), J = () => ({
  tag: "strong"
}), B = () => ({
  tag: "code"
}), K = () => ({
  tag: "i"
}), G = (o) => {
  if (!o.attrs)
    return {
      tag: ""
    };
  const e = new k().escapeHTML, t = { ...o.attrs }, { linktype: s = "url" } = o.attrs;
  if (delete t.linktype, t.href && (t.href = e(o.attrs.href || "")), O(s) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), t.custom) {
    for (const r in t.custom)
      t[r] = t.custom[r];
    delete t.custom;
  }
  return {
    tag: [
      {
        tag: "a",
        attrs: t
      }
    ]
  };
}, Y = (o) => ({
  tag: [
    {
      tag: "span",
      attrs: o.attrs
    }
  ]
}), Q = () => ({
  tag: "sub"
}), W = () => ({
  tag: "sup"
}), X = (o) => ({
  tag: [
    {
      tag: "span",
      attrs: o.attrs
    }
  ]
}), Z = (o) => {
  var t;
  return (t = o.attrs) != null && t.color ? {
    tag: [
      {
        tag: "span",
        attrs: {
          style: `background-color:${o.attrs.color};`
        }
      }
    ]
  } : {
    tag: ""
  };
}, D = (o) => {
  var t;
  return (t = o.attrs) != null && t.color ? {
    tag: [
      {
        tag: "span",
        attrs: {
          style: `color:${o.attrs.color}`
        }
      }
    ]
  } : {
    tag: ""
  };
}, ee = {
  nodes: {
    horizontal_rule: A,
    blockquote: C,
    bullet_list: L,
    code_block: E,
    hard_break: U,
    heading: H,
    image: N,
    list_item: j,
    ordered_list: I,
    paragraph: M,
    emoji: z
  },
  marks: {
    bold: q,
    strike: F,
    underline: V,
    strong: J,
    code: B,
    italic: K,
    link: G,
    styled: Y,
    subscript: Q,
    superscript: W,
    anchor: X,
    highlight: Z,
    textStyle: D
  }
}, te = function(o) {
  const e = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;"
  }, t = /[&<>"']/g, s = RegExp(t.source);
  return o && s.test(o) ? o.replace(t, (r) => e[r]) : o;
};
class T {
  constructor(e) {
    h(this, "marks");
    h(this, "nodes");
    e || (e = ee), this.marks = e.marks || [], this.nodes = e.nodes || [];
  }
  addNode(e, t) {
    this.nodes[e] = t;
  }
  addMark(e, t) {
    this.marks[e] = t;
  }
  render(e, t = { optimizeImages: !1 }) {
    if (e && e.content && Array.isArray(e.content)) {
      let s = "";
      return e.content.forEach((r) => {
        s += this.renderNode(r);
      }), t.optimizeImages ? this.optimizeImages(s, t.optimizeImages) : s;
    }
    return console.warn(
      `The render method must receive an Object with a "content" field.
			The "content" field must be an array of nodes as the type ISbRichtext.
			ISbRichtext:
				content?: ISbRichtext[]
				marks?: ISbRichtext[]
				attrs?: any
				text?: string
				type: string
				
				Example:
				{
					content: [
						{
							content: [
								{
									text: 'Hello World',
									type: 'text'
								}
							],
							type: 'paragraph'
						}
					],
					type: 'doc'
				}`
    ), "";
  }
  optimizeImages(e, t) {
    let s = 0, r = 0, i = "", n = "";
    typeof t != "boolean" && (typeof t.width == "number" && t.width > 0 && (i += `width="${t.width}" `, s = t.width), typeof t.height == "number" && t.height > 0 && (i += `height="${t.height}" `, r = t.height), (t.loading === "lazy" || t.loading === "eager") && (i += `loading="${t.loading}" `), typeof t.class == "string" && t.class.length > 0 && (i += `class="${t.class}" `), t.filters && (typeof t.filters.blur == "number" && t.filters.blur >= 0 && t.filters.blur <= 100 && (n += `:blur(${t.filters.blur})`), typeof t.filters.brightness == "number" && t.filters.brightness >= -100 && t.filters.brightness <= 100 && (n += `:brightness(${t.filters.brightness})`), t.filters.fill && (t.filters.fill.match(/[0-9A-Fa-f]{6}/g) || t.filters.fill === "transparent") && (n += `:fill(${t.filters.fill})`), t.filters.format && ["webp", "png", "jpeg"].includes(t.filters.format) && (n += `:format(${t.filters.format})`), typeof t.filters.grayscale == "boolean" && t.filters.grayscale && (n += ":grayscale()"), typeof t.filters.quality == "number" && t.filters.quality >= 0 && t.filters.quality <= 100 && (n += `:quality(${t.filters.quality})`), t.filters.rotate && [90, 180, 270].includes(t.filters.rotate) && (n += `:rotate(${t.filters.rotate})`), n.length > 0 && (n = "/filters" + n))), i.length > 0 && (e = e.replace(/<img/g, `<img ${i.trim()}`));
    const l = s > 0 || r > 0 || n.length > 0 ? `${s}x${r}${n}` : "";
    return e = e.replace(
      /a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g,
      `a.storyblok.com/f/$1/$2.$3/m/${l}`
    ), typeof t != "boolean" && (t.sizes || t.srcset) && (e = e.replace(/<img.*?src=["|'](.*?)["|']/g, (a) => {
      var u, g;
      const c = a.match(
        /a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g
      );
      if (c && c.length > 0) {
        const y = {
          srcset: (u = t.srcset) == null ? void 0 : u.map((f) => {
            if (typeof f == "number")
              return `//${c}/m/${f}x0${n} ${f}w`;
            if (typeof f == "object" && f.length === 2) {
              let b = 0, _ = 0;
              return typeof f[0] == "number" && (b = f[0]), typeof f[1] == "number" && (_ = f[1]), `//${c}/m/${b}x${_}${n} ${b}w`;
            }
          }).join(", "),
          sizes: (g = t.sizes) == null ? void 0 : g.map((f) => f).join(", ")
        };
        let d = "";
        return y.srcset && (d += `srcset="${y.srcset}" `), y.sizes && (d += `sizes="${y.sizes}" `), a.replace(/<img/g, `<img ${d.trim()}`);
      }
      return a;
    })), e;
  }
  renderNode(e) {
    const t = [];
    e.marks && e.marks.forEach((r) => {
      const i = this.getMatchingMark(r);
      i && i.tag !== "" && t.push(this.renderOpeningTag(i.tag));
    });
    const s = this.getMatchingNode(e);
    return s && s.tag && t.push(this.renderOpeningTag(s.tag)), e.content ? e.content.forEach((r) => {
      t.push(this.renderNode(r));
    }) : e.text ? t.push(te(e.text)) : s && s.singleTag ? t.push(this.renderTag(s.singleTag, " /")) : s && s.html ? t.push(s.html) : e.type === "emoji" && t.push(this.renderEmoji(e)), s && s.tag && t.push(this.renderClosingTag(s.tag)), e.marks && e.marks.slice(0).reverse().forEach((r) => {
      const i = this.getMatchingMark(r);
      i && i.tag !== "" && t.push(this.renderClosingTag(i.tag));
    }), t.join("");
  }
  renderTag(e, t) {
    return e.constructor === String ? `<${e}${t}>` : e.map((r) => {
      if (r.constructor === String)
        return `<${r}${t}>`;
      {
        let i = `<${r.tag}`;
        if (r.attrs)
          for (const n in r.attrs) {
            const l = r.attrs[n];
            l !== null && (i += ` ${n}="${l}"`);
          }
        return `${i}${t}>`;
      }
    }).join("");
  }
  renderOpeningTag(e) {
    return this.renderTag(e, "");
  }
  renderClosingTag(e) {
    return e.constructor === String ? `</${e}>` : e.slice(0).reverse().map((s) => s.constructor === String ? `</${s}>` : `</${s.tag}>`).join("");
  }
  getMatchingNode(e) {
    const t = this.nodes[e.type];
    if (typeof t == "function")
      return t(e);
  }
  getMatchingMark(e) {
    const t = this.marks[e.type];
    if (typeof t == "function")
      return t(e);
  }
  renderEmoji(e) {
    if (e.attrs.emoji)
      return e.attrs.emoji;
    const t = [
      {
        tag: "img",
        attrs: {
          src: e.attrs.fallbackImage,
          draggable: "false",
          loading: "lazy",
          align: "absmiddle"
        }
      }
    ];
    return this.renderTag(t, " /");
  }
}
class se {
  constructor(e) {
    h(this, "baseURL");
    h(this, "timeout");
    h(this, "headers");
    h(this, "responseInterceptor");
    h(this, "fetch");
    h(this, "ejectInterceptor");
    h(this, "url");
    h(this, "parameters");
    h(this, "fetchOptions");
    this.baseURL = e.baseURL, this.headers = e.headers || new Headers(), this.timeout = e != null && e.timeout ? e.timeout * 1e3 : 0, this.responseInterceptor = e.responseInterceptor, this.fetch = (...t) => e.fetch ? e.fetch(...t) : fetch(...t), this.ejectInterceptor = !1, this.url = "", this.parameters = {}, this.fetchOptions = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("get");
  }
  post(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("post");
  }
  put(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("put");
  }
  delete(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("delete");
  }
  async _responseHandler(e) {
    const t = [], s = {
      data: {},
      headers: {},
      status: 0,
      statusText: ""
    };
    e.status !== 204 && await e.json().then((r) => {
      s.data = r;
    });
    for (const r of e.headers.entries())
      t[r[0]] = r[1];
    return s.headers = { ...t }, s.status = e.status, s.statusText = e.statusText, s;
  }
  async _methodHandler(e) {
    let t = `${this.baseURL}${this.url}`, s = null;
    if (e === "get") {
      const a = new k();
      t = `${this.baseURL}${this.url}?${a.stringify(
        this.parameters
      )}`;
    } else
      s = JSON.stringify(this.parameters);
    const r = new URL(t), i = new AbortController(), { signal: n } = i;
    let l;
    this.timeout && (l = setTimeout(() => i.abort(), this.timeout));
    try {
      const a = await this.fetch(`${r}`, {
        method: e,
        headers: this.headers,
        body: s,
        signal: n,
        ...this.fetchOptions
      });
      this.timeout && clearTimeout(l);
      const c = await this._responseHandler(
        a
      );
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(c)) : this._statusHandler(c);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  setFetchOptions(e = {}) {
    Object.keys(e).length > 0 && "method" in e && delete e.method, this.fetchOptions = { ...e };
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(e) {
    const t = /20[0-6]/g;
    return new Promise((s, r) => {
      if (t.test(`${e.status}`))
        return s(e);
      const i = {
        message: e.statusText,
        status: e.status,
        response: Array.isArray(e.data) ? e.data[0] : e.data.error || e.data.slug
      };
      r(i);
    });
  }
}
const $ = "SB-Agent", R = {
  defaultAgentName: "SB-JS-CLIENT",
  defaultAgentVersion: "SB-Agent-Version",
  packageVersion: "6.0.0"
};
let m = {};
const p = {};
class ie {
  /**
   *
   * @param config ISbConfig interface
   * @param endpoint string, optional
   */
  constructor(e, t) {
    h(this, "client");
    h(this, "maxRetries");
    h(this, "retriesDelay");
    h(this, "throttle");
    h(this, "accessToken");
    h(this, "cache");
    h(this, "helpers");
    h(this, "resolveCounter");
    h(this, "relations");
    h(this, "links");
    h(this, "richTextResolver");
    h(this, "resolveNestedRelations");
    h(this, "stringifiedStoriesCache");
    let s = e.endpoint || t;
    if (!s) {
      const n = new k().getRegionURL, l = e.https === !1 ? "http" : "https";
      e.oauthToken ? s = `${l}://${n(e.region)}/v1` : s = `${l}://${n(e.region)}/v2`;
    }
    const r = new Headers();
    if (r.set("Content-Type", "application/json"), r.set("Accept", "application/json"), e.headers)
      for (const n in e.headers)
        r.set(n, e.headers[n]);
    r.has($) || (r.set($, R.defaultAgentName), r.set(
      R.defaultAgentVersion,
      R.packageVersion
    ));
    let i = 5;
    e.oauthToken && (r.set("Authorization", e.oauthToken), i = 3), e.rateLimit && (i = e.rateLimit), e.richTextSchema ? this.richTextResolver = new T(e.richTextSchema) : this.richTextResolver = new T(), e.componentResolver && this.setComponentResolver(e.componentResolver), this.maxRetries = e.maxRetries || 10, this.retriesDelay = 300, this.throttle = x(this.throttledRequest, i, 1e3), this.accessToken = e.accessToken || "", this.relations = {}, this.links = {}, this.cache = e.cache || { clear: "manual" }, this.helpers = new k(), this.resolveCounter = 0, this.resolveNestedRelations = e.resolveNestedRelations || !0, this.stringifiedStoriesCache = {}, this.client = new se({
      baseURL: s,
      timeout: e.timeout || 0,
      headers: r,
      responseInterceptor: e.responseInterceptor,
      fetch: e.fetch
    });
  }
  setComponentResolver(e) {
    this.richTextResolver.addNode("blok", (t) => {
      let s = "";
      return t.attrs.body && t.attrs.body.forEach((r) => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  }
  parseParams(e) {
    return e.token || (e.token = this.getToken()), e.cv || (e.cv = p[e.token]), Array.isArray(e.resolve_relations) && (e.resolve_relations = e.resolve_relations.join(",")), typeof e.resolve_relations < "u" && (e.resolve_level = 2), e;
  }
  factoryParamOptions(e, t) {
    return this.helpers.isCDNUrl(e) ? this.parseParams(t) : t;
  }
  makeRequest(e, t, s, r) {
    const i = this.factoryParamOptions(
      e,
      this.helpers.getOptionsPage(t, s, r)
    );
    return this.cacheResponse(e, i);
  }
  get(e, t, s) {
    t || (t = {});
    const r = `/${e}`, i = this.factoryParamOptions(r, t);
    return this.client.setFetchOptions(s), this.cacheResponse(r, i);
  }
  async getAll(e, t, s, r) {
    const i = (t == null ? void 0 : t.per_page) || 25, n = `/${e}`, l = n.split("/"), a = s || l[l.length - 1], c = 1, u = await this.makeRequest(n, t, i, c), g = u.total ? Math.ceil(u.total / i) : 1;
    this.client.setFetchOptions(r);
    const y = await this.helpers.asyncMap(
      this.helpers.range(c, g),
      (d) => this.makeRequest(n, t, i, d + 1)
    );
    return this.helpers.flatMap(
      [u, ...y],
      (d) => Object.values(d.data[a])
    );
  }
  post(e, t, s) {
    const r = `/${e}`;
    return this.client.setFetchOptions(s), Promise.resolve(this.throttle("post", r, t));
  }
  put(e, t, s) {
    const r = `/${e}`;
    return this.client.setFetchOptions(s), Promise.resolve(this.throttle("put", r, t));
  }
  delete(e, t, s) {
    const r = `/${e}`;
    return this.client.setFetchOptions(s), Promise.resolve(this.throttle("delete", r, t));
  }
  getStories(e, t) {
    return this.client.setFetchOptions(t), this._addResolveLevel(e), this.get("cdn/stories", e);
  }
  getStory(e, t, s) {
    return this.client.setFetchOptions(s), this._addResolveLevel(t), this.get(`cdn/stories/${e}`, t);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _addResolveLevel(e) {
    typeof e.resolve_relations < "u" && (e.resolve_level = 2);
  }
  _cleanCopy(e) {
    return JSON.parse(JSON.stringify(e));
  }
  _insertLinks(e, t, s) {
    const r = e[t];
    r && r.fieldtype == "multilink" && r.linktype == "story" && typeof r.id == "string" && this.links[s][r.id] ? r.story = this._cleanCopy(this.links[s][r.id]) : r && r.linktype === "story" && typeof r.uuid == "string" && this.links[s][r.uuid] && (r.story = this._cleanCopy(this.links[s][r.uuid]));
  }
  /**
   *
   * @param resolveId A counter number as a string
   * @param uuid The uuid of the story
   * @returns string | object
   */
  getStoryReference(e, t) {
    return this.relations[e][t] ? (this.stringifiedStoriesCache[t] || (this.stringifiedStoriesCache[t] = JSON.stringify(
      this.relations[e][t]
    )), JSON.parse(this.stringifiedStoriesCache[t])) : t;
  }
  _insertRelations(e, t, s, r) {
    s.indexOf(`${e.component}.${t}`) > -1 && (typeof e[t] == "string" ? e[t] = this.getStoryReference(r, e[t]) : Array.isArray(e[t]) && (e[t] = e[t].map((i) => this.getStoryReference(r, i)).filter(Boolean)));
  }
  iterateTree(e, t, s) {
    const r = (i) => {
      if (i != null) {
        if (i.constructor === Array)
          for (let n = 0; n < i.length; n++)
            r(i[n]);
        else if (i.constructor === Object) {
          if (i._stopResolving)
            return;
          for (const n in i)
            (i.component && i._uid || i.type === "link") && (this._insertRelations(
              i,
              n,
              t,
              s
            ), this._insertLinks(
              i,
              n,
              s
            )), r(i[n]);
        }
      }
    };
    r(e.content);
  }
  async resolveLinks(e, t, s) {
    let r = [];
    if (e.link_uuids) {
      const i = e.link_uuids.length, n = [], l = 50;
      for (let a = 0; a < i; a += l) {
        const c = Math.min(i, a + l);
        n.push(e.link_uuids.slice(a, c));
      }
      for (let a = 0; a < n.length; a++)
        (await this.getStories({
          per_page: l,
          language: t.language,
          version: t.version,
          by_uuids: n[a].join(",")
        })).data.stories.forEach(
          (u) => {
            r.push(u);
          }
        );
    } else
      r = e.links;
    r.forEach((i) => {
      this.links[s][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(e, t, s) {
    let r = [];
    if (e.rel_uuids) {
      const i = e.rel_uuids.length, n = [], l = 50;
      for (let a = 0; a < i; a += l) {
        const c = Math.min(i, a + l);
        n.push(e.rel_uuids.slice(a, c));
      }
      for (let a = 0; a < n.length; a++)
        (await this.getStories({
          per_page: l,
          language: t.language,
          version: t.version,
          by_uuids: n[a].join(","),
          excluding_fields: t.excluding_fields
        })).data.stories.forEach((u) => {
          r.push(u);
        });
    } else
      r = e.rels;
    r && r.length > 0 && r.forEach((i) => {
      this.relations[s][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  /**
   *
   * @param responseData
   * @param params
   * @param resolveId
   * @description Resolves the relations and links of the stories
   * @returns Promise<void>
   *
   */
  async resolveStories(e, t, s) {
    var i, n;
    let r = [];
    if (this.links[s] = {}, this.relations[s] = {}, typeof t.resolve_relations < "u" && t.resolve_relations.length > 0 && (typeof t.resolve_relations == "string" && (r = t.resolve_relations.split(",")), await this.resolveRelations(e, t, s)), t.resolve_links && ["1", "story", "url", "link"].indexOf(t.resolve_links) > -1 && ((i = e.links) != null && i.length || (n = e.link_uuids) != null && n.length) && await this.resolveLinks(e, t, s), this.resolveNestedRelations)
      for (const l in this.relations[s])
        this.iterateTree(
          this.relations[s][l],
          r,
          s
        );
    e.story ? this.iterateTree(e.story, r, s) : e.stories.forEach((l) => {
      this.iterateTree(l, r, s);
    }), this.stringifiedStoriesCache = {}, delete this.links[s], delete this.relations[s];
  }
  async cacheResponse(e, t, s) {
    const r = this.helpers.stringify({ url: e, params: t }), i = this.cacheProvider();
    if (this.cache.clear === "auto" && t.version === "draft" && await this.flushCache(), t.version === "published" && e != "/cdn/spaces/me") {
      const n = await i.get(r);
      if (n)
        return Promise.resolve(n);
    }
    return new Promise(async (n, l) => {
      var a;
      try {
        const c = await this.throttle("get", e, t);
        if (c.status !== 200)
          return l(c);
        let u = { data: c.data, headers: c.headers };
        if ((a = c.headers) != null && a["per-page"] && (u = Object.assign({}, u, {
          perPage: c.headers["per-page"] ? parseInt(c.headers["per-page"]) : 0,
          total: c.headers["per-page"] ? parseInt(c.headers.total) : 0
        })), u.data.story || u.data.stories) {
          const g = this.resolveCounter = ++this.resolveCounter % 1e3;
          await this.resolveStories(u.data, t, `${g}`);
        }
        return t.version === "published" && e != "/cdn/spaces/me" && await i.set(r, u), u.data.cv && t.token && (t.version === "draft" && p[t.token] != u.data.cv && await this.flushCache(), p[t.token] = t.cv ? t.cv : u.data.cv), n(u);
      } catch (c) {
        if (c.response && c.status === 429 && (s = typeof s > "u" ? 0 : s + 1, s < this.maxRetries))
          return console.log(`Hit rate limit. Retrying in ${this.retriesDelay / 1e3} seconds.`), await this.helpers.delay(this.retriesDelay), this.cacheResponse(e, t, s).then(n).catch(l);
        l(c);
      }
    });
  }
  throttledRequest(e, t, s) {
    return this.client[e](t, s);
  }
  cacheVersions() {
    return p;
  }
  cacheVersion() {
    return p[this.accessToken];
  }
  setCacheVersion(e) {
    this.accessToken && (p[this.accessToken] = e);
  }
  clearCacheVersion() {
    this.accessToken && (p[this.accessToken] = 0);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(e) {
            return Promise.resolve(m[e]);
          },
          getAll() {
            return Promise.resolve(m);
          },
          set(e, t) {
            return m[e] = t, Promise.resolve(void 0);
          },
          flush() {
            return m = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom)
          return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve();
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this.clearCacheVersion(), this;
  }
}
export {
  T as RichtextResolver,
  ee as RichtextSchema,
  $ as STORYBLOK_AGENT,
  R as STORYBLOK_JS_CLIENT_AGENT,
  se as SbFetch,
  k as SbHelpers,
  ie as default
};
