import { Component, Inject, OnInit, PLATFORM_ID, ApplicationRef, NgZone } from '@angular/core';
import { CommonModule, DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { DynamicComponent, DynamicIoDirective } from 'ng-dynamic-component'
import {
  StoryblokService, StoryblokDirective, WEB_COMPONENTS_CONFIG,
  WebComponentsConfig, AppComponentBase, ComponentService
} from '@codeorbit/webcomponents';

declare const window: any;

@Component({
  selector: 'cbit-root',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterOutlet,
    DynamicComponent, StoryblokDirective,
    DynamicIoDirective],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AppComponentBase implements OnInit {

  constructor(
    storyblokService: StoryblokService,
    componentService: ComponentService,
    router: Router,
    appRef: ApplicationRef,
    ngZone: NgZone,
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) document: Document,
    @Inject(WEB_COMPONENTS_CONFIG) config: WebComponentsConfig) {

    super(storyblokService, componentService, router, platformId, document, appRef, ngZone, config);

    this.enableStoryBlokEditor();
  }

  ngOnInit() {
    this.handleNavigation();
    this.initialize();
  }

}
